/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-07-26 09:34:32
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-09-04 14:15:33
 * @FilePath: \do2f:\Workspace\TechMaker\src\mixin\CommonMix.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import { i18nRender } from '@/locales'


export default {
    name:'CommonMix',
    data:{

    },
    methods: {
        // i18nRender,
        //https://github.com/euvl/vue-notification#styling
        showSuccess(message) {
            this.$notify({type:'success',message})
        },
        showError(message){
            this.$notify({type:'error',message})
        },
        showInfo(message){
            this.$notify({type:'info',message})
        },
        openWebSite(url) {
            window.open(url, "__blank");
        },

        showLoading(message) {
            this.loading = this.$loading.service({ fullscreen: true,background:'rgb(25, 25, 26,0.5)' });
        },
        closeLoading(message) { 
            this.loading.close();
        }
    },
}
