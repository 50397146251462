/*
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-05-06 15:09:43
 * @LastEditTime: 2023-12-22 16:59:10
 * @LastEditors: 叹息桥丶 648161532@qq.com
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue"; 
import Course from "../views/Course.vue";


import News from "../views/News.vue";
import Serve from "../views/Serve.vue";
import Notice from "../views/Notice.vue";
import Detail from "../views/Detail.vue";


Vue.use(VueRouter);
const routes = [
  {path: "/",name: "Home",component: Home,},
  {path: '/home',name: 'home',component: Home},
  {path: '/about',name: 'about',component: About},
  {path: '/course',name: 'course',component: Course},
  {path: '/serve',name: 'serve',component: Serve},
  {path: '/serve/detail/:id',name: 'serveDetail',component: Detail},
  {path: '/news',name: 'news',component: News},
  {path: '/news/detail/:id',name: 'newsDetail',component: Detail},
  {path: '/notice',name: 'notice',component: Notice},
  {path: '/notice/detail/:id',name: 'noticeDetail',component: Detail},
  {path: "*",name: "404",component: Home,},
];


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
