/*
 * @Author: 叹息桥
 * @Description:
 * @Date: 2022-04-27 20:31:26
 * @LastEditTime: 2023-08-28 15:26:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 */

import Web3Wallet from "@/contracts/index.js";
import BigNumber from "bignumber.js";
import rpc_config from "@/contracts/config";
import * as Api from '@/api/test.js'

const chain = rpc_config;
export default {
  created() {
    // that.walletTimer = setInterval((fn) => {
    //   that.loadAccount()
    // }, 3000)
  },
  beforeDestroy() {
    clearInterval(this.walletTimer);
    this.walletTimer = null;
  },
  data() {
    return {
      // 链信息
      web3Wallet: null,
      walletTimer: null,
      balance: 0,//余额
      chain: chain
    };
  },

  methods: {
    // 初始化钱包
    async initWeb3() {
      // 初始化web3
      this.web3Wallet = this.$store.state.web3Wallet = await Web3Wallet.init();
      console.log( "web3初始化完成",this.web3Wallet)
      this.listenAccount();
      if(localStorage.getItem('userToken')){
        this.connectWeb3();
      }
    },
    async connectWeb3() {
      this.web3Wallet = this.$store.state.web3Wallet
      const that = this;
      const walletAddress = await this.web3Wallet.connect();
      await this.web3Wallet.checkNetId();
      if (walletAddress) {
        //有token，钱包对
        if (localStorage.getItem('userToken')
         && walletAddress === localStorage.getItem('walletAddress')) 
        {
          that.$store.dispatch('setUserToken')
          that.$store.dispatch('setWalletAddress')
          return false
        }
        const signString = await Api.getNonce({ publicAddress: walletAddress })
        // 获取钱包端签名
        const secret = await this.web3Wallet.walletEncryptStr(signString.data.nonce, walletAddress)
        // 获取登录后的token
        await Api.login({identify: walletAddress,secret}).then((res) => {
          if (!res.status) { return false }
          that.$store.dispatch('setUserToken',res.data.token)
          that.$store.dispatch('setWalletAddress', walletAddress)
          // 获得用户信息
          // getUserInfo({ user_address: walletAddress }).then((res) => {
          //   this.$store.state.userInfo = { ...res.data }
          // });
        });
      }
    },

    // 退出登录
    disWeb3() {
      this.$store.dispatch('logout')
    },

    // 获取账号
    async loadAccount() {
      var that = this;
      const walletAddress = that.$store.getters.walletAddress
      if (!walletAddress) { return false }
      this.web3Wallet.getBalance(walletAddress).then(function (_balance) {
        that.balance = this.web3Wallet.web3.utils.fromWei(_balance, "ether");
      });
    },

    // 监听账号切换
    listenAccount() {
      var that = this;
      this.web3Wallet = this.$store.state.web3Wallet

      this.web3Wallet.monitorAccount((walletAccount) => {
        const storeAccount = this.$store.state.walletAddress
        console.log('监听账户切换 ','store:', storeAccount,'wallet:', walletAccount)
        if ((!walletAccount) || (storeAccount !== walletAccount) ) {
          that.$store.dispatch('logout')
        }
      });
    },
  },
}
