/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-07-26 09:34:32
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-08-31 10:12:58
 * @FilePath: \do2f:\Workspace\TechMaker\src\contracts\config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
let rpc_config = {
  'bsc-test':{
    "net_id":"0x61",
    "chain_name":"BNB-test",
    "coin_unit": "TBNB",
    "router_address": "",
    "zero_address": "0x0000000000000000000000000000000000000000",
    "burn_address": "0x000000000000000000000000000000000000dead",
    "rpc_url": "https://testnet.bscscan.com/",

    // "do2_token": "0xAFe7EF6b4e7931E8def1494dCF55d99565537016",
    // "claim_token": "0xE5bE4670d877890b0ad0aeC510056b804d2d3D29",
    // "create_presale_address": "0xc836c339f0903Dfa5b2ACfa1603C142c06DFee59",
    // "token_address":"0xaD3Aa62e566eFAd57545707a3d74f01Dbc3C47A1",
  },
  'Arbitrum':{
      "net_id":"0xa4b1",
      "chain_name":"ETH",
      "coin_unit": "ETH",
      "router_address": "",
      "zero_address": "0x0000000000000000000000000000000000000000",
      "burn_address": "0x000000000000000000000000000000000000dead",
      "rpc_url": "https://arb1.arbitrum.io/rpc"
  },
  'BNB':{
    "net_id":"0x38",
    "chain_name":"BNB",
    "coin_unit": "BNB",
    "zero_address": "0x0000000000000000000000000000000000000000",
    "burn_address": "0x000000000000000000000000000000000000dead",
    "rpc_url": "https://bsc-dataseed.binance.org/"
  },
  'ETH':{
    "net_id":"0x1",
    "chain_name":"ETH",
    "coin_unit": "ETH",
    "router_address": "",
    "zero_address": "0x0000000000000000000000000000000000000000",
    "burn_address": "0x000000000000000000000000000000000000dead",
    "create_presale_address": "",
    "rpc_url": "https://mainnet.infura.io/v3/"
  }
};


// if (process.env.NODE_ENV === "production") {
  // rpc_config = {
  //   net_id: "0xB3",
  //   chain_name: "ABEY",
  //   rpc_url: "https://rpc.abeychain.com",
  //   block_explorer_url: "https://scan.abeychain.com",
  //   coin_unit: "ABEY",

  //   gas_fee: 20,
  //   gas_amount_mul: 1.3,

 
  //   "ANSRegistry":"0x82fae045e418B1fA15ec12C399e24a51925865F7",
  //   "ABEYRegistrarController":"0x31289C7a00604D2ffA1829c59EE042BAC5691601",
  //   "BaseInformation":"0xa3cf3c144484258CbD01aC21e59fbc648ac592E6",
  //   "BaseRegistrarImplementation":"0xAD908cb0aca0e1EBe3434274F6aE5d2151963047",
  //   "PublicResolver":"0x54cCfBE565B0D1B9981a3cccd6A5ec65F8d1B4Dd",
  //   "ShopMarket":"0xb075d7d61770d33734B970717065DAb08472E9fB",
  //   "pair":"0xE59f75276C4eF762CBC0B9A0e7D0e185e2f979FB",
  //   "UsdtToken":"0x7286fd763bBd56CF278Dc311dE9A053E6837B62E",

  //   baseUrl:'https://api.abeyid.com',
  //   chain_param:'abey',
  // };
// }

export default rpc_config;
