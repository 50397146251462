/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-08-01 11:41:33
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-23 19:07:33
 * @FilePath: \do2f:\Workspace\TechMaker\src\locals\zh-cn\home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    home:{ 
        btn:"關於我們",
        detail:"查看詳情",
    },
    serve:{ 
        title:"NewWorld 生態",
        btn:"更多生態"
    },
    updates:{ 
        title:"最新动态",
        btn:"查看更多"
    },
    menu:{ 
        home:"首頁",
        about:"關於我們",
        serve:"NewWorld 生態",
            pool:"產能池",
            battle:"空間戰場",
            ecology:"NFT生態",
            more:"更多",
        updates:"最新動態",
            news:"新聞",
            notice:"公告",
        course:"路線圖",
    }
}
      
    
    