"use strict";
import configJSON from '@/contracts/config.js';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import Web3 from "web3";
import store from '@/store';
import rpc_config from "@/contracts/config";


var status = {
  SUCCESS: 0,
  DISABLE_CONNECT: 1, //用户取消钱包连接
  WALLET_NULL: 2, //钱包没有安装
  ACCOUNT_NULL: 3, //钱包锁住
  NET_ID_ERROR: 4, //无法获得网络ID
};

var Web3Wallet = {
  web3Provider: null,//web供应者 inject scan
  web3: null,
  address: null, //钱包地址
  connected: false, //是否已经连接上
  netId: 0, //网络ID
  netName: "",//网络名称
  coinUnit: "",//币种名称
  swapName: "",
  swapUrl: "",
  checkWalletTimer: null,//检查钱包计时器
  netExplorer: null, //浏览器
  contract_address: null,//合约地址

  // 是否链接钱包
  isConnected() {
    //return true;
    if (Web3Wallet.connected) {
      return true;
    } else {
      return localStorage.getItem("WALLET_CONNECTED");
    }
  },
  init:async function () {
    const chain = rpc_config[ store.state.chainKey];
    Web3Wallet.web3 = new Web3(chain.rpc_url);
    let rpc = {}
    rpc[parseInt(chain.net_id, 16)] = chain.rpc_url
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: { network: chain.chain_name, rpc }
      }
    };
    const web3Modal = new Web3Modal({
      network: chain.chain_name, // optional
      cacheProvider: true, // optional 是否自动选择上次的
      providerOptions // required
    });
    Web3Wallet.web3Provider = await web3Modal.connect()
    .then((data) => {
      Web3Wallet.web3Provider = data
      Web3Wallet.web3 = new Web3(Web3Wallet.web3Provider);
    }).catch(err => {
      Web3Wallet.web3 = new Web3(chain.rpc_url);
      localStorage.setItem("WALLET_PROVIDER", true);
    });
    return Web3Wallet
  },
  connect: async function () {
    const chain = rpc_config[ store.state.chainKey];
    let result = true, rpc = {}
    rpc[parseInt(chain.net_id, 16)] = chain.rpc_url
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: { network: chain.chain_name, rpc }
      }
    };
    const web3Modal = new Web3Modal({
      network: chain.chain_name, // optional
      cacheProvider: true, // optional 是否自动选择上次的
      providerOptions // required
    });
    Web3Wallet.web3Provider = await web3Modal.connect().then((data) => {
      Web3Wallet.web3Provider = data
      Web3Wallet.web3 = new Web3(Web3Wallet.web3Provider);
    }).catch(err => {
      Web3Wallet.web3 = new Web3(chain.rpc_url);
      localStorage.setItem("WALLET_PROVIDER", true);
      result = false
    });
    if (!result) {
      return false
    }
    return new Promise(function (resolve, reject) {
      try {
        Web3Wallet.web3.eth.getAccounts(function (err, accounts) {
          if (err != null || accounts.length == 0) {
            console.log("账号为空，可能是钱包被锁住或者未创建账户");
            Web3Wallet.disConnect();
            resolve(null);
          } else {
            localStorage.setItem("WALLET_PROVIDER", true);
            Web3Wallet.address = accounts[0];
            resolve(accounts[0].toLowerCase());
          }
        });
      } catch (e) {
        Web3Wallet.disConnect();
        reject(null);
      }
    });
  },

  // 断开链接钱包
  disConnect: async function () {
    try {
      Web3Wallet.web3Provider = null;
    }
    catch (e) {
      console.log(e);
    }
    Web3Wallet.address = null;
    Web3Wallet.connected = false;
    localStorage.removeItem("WALLET_CONNECTED");
    localStorage.removeItem("WALLET_PROVIDER");
    localStorage.removeItem("accountToken");
  },

  // 检查账号
  checkAccount: function () {
    return new Promise(function (resolve, reject) {
      Web3Wallet.web3.eth.getAccounts(function (err, accounts) {
        if (err != null || accounts.length == 0) {
          console.log("账号为空，可能是钱包被锁住或者未创建账户");
          Web3Wallet.connected = false;
          localStorage.removeItem("WALLET_CONNECTED");
          localStorage.removeItem("WALLET_PROVIDER");
          reject(status.ACCOUNT_NULL);
        } else {
          var address = accounts[0].toLowerCase();
          resolve(address); //成功
        }
      });
    });
  },

  // 监听账号切换
  monitorAccount: function (listener) {
    window.ethereum.on("accountsChanged", (params) => {
      listener(params[0]);
    });
  },
  // 获取账号签名
  walletEncryptStr: async function (encryptStr, address) {
    const utf8ToHex = Web3Wallet.web3.utils.utf8ToHex(encryptStr)
    return await Web3Wallet.web3.eth.personal.sign(utf8ToHex, address)
  },

  //查询余额wei Promise
  getBalance: async function (_address) {
    return await Web3Wallet.web3.eth.getBalance(_address);
  },

  //查看账户
  getAccounts: async function (_address) {
    return new Promise(function (resolve, reject) {
      try {
        Web3Wallet.web3.eth.getAccounts(function (err, accounts) {
          if (err != null || accounts.length == 0) {
            console.log("账号为空，可能是钱包被锁住或者未创建账户");
            Web3Wallet.disConnect();
            reject(status.ACCOUNT_NULL);
          } else {
            Web3Wallet.address = accounts[0];
            Web3Wallet.checkNetId().then(function (config) {
              localStorage.setItem("WALLET_CONNECTED", true);
              localStorage.setItem("WALLET_PROVIDER", true);
              Web3Wallet.connected = true;
              resolve(Web3Wallet.address.toLowerCase());
            }).catch(function () {
              Web3Wallet.disConnect();
              reject(status.NET_ID_ERROR)
            });
          }
        });
      } catch (e) {
        Web3Wallet.disConnect();
        reject(result);
      }
    });
  },

  //生成合约
  contract: function (name, address = null) {
    
    address = (!address)?rpc_config[name]:address
    var data = require("./contracts/" + name + ".json");
    var contractInstant = new Web3Wallet.web3.eth.Contract(data.abi, address);
    var gasPrice = Web3Wallet.web3.utils.toWei("10", "gwei"); //默认的手续费
    contractInstant.send = function (func_name, params, value = 0) {
      return new Promise((resolve, reject) => {
        contractInstant.methods[func_name](...params).estimateGas({
          from: Web3Wallet.address,
          value: value
        }).then(function (gasAmount) {
          //这里也可以对超过一定数额的Gas做出错误提示
          gasAmount = parseInt(gasAmount * 1.3)
          //开始执行合约
          console.log('合约:', Web3Wallet.address, gasPrice, gasAmount, value)
          contractInstant.methods[func_name](...params).send({
            from: Web3Wallet.address,
            gasPrice: gasPrice,
            gas: gasAmount,
            value: value
          })
            .on("transactionHash", function (hash) {
              console.log("获得到交易单号:" + hash);
            })
            .on('confirmation', function (confirmationNumber, receipt) {
              //确认超过12保证不分叉，但时间会比较长
              // console.log("确认次数" + confirmationNumber);
            })
            .on('receipt', function (receipt) {
              //成功出块，但有可能分叉
              console.log(receipt);
              resolve(receipt); //成功
            })
            .on("error", function (err) {
              // err.message = contractError
              reject(err)
              console.log("交易发生错误");
              console.log(name + "合约方法:" + func_name + "运行失败:参数如下", params);
            });

        }).catch(function (err) {
          // err.message = contractError
          // Message.error('Transaction will never success.')
          console.error("合约估算错误");
          console.error(name + "合约方法:" + func_name + "估算失败:参数如下", params);

          console.log(err);
          reject(err)
        });
      });
    };
    contractInstant.call = function (func_name, params) {
      return new Promise((resolve, reject) => {
        contractInstant.methods[func_name](...params).call({
          from: Web3Wallet.address
        }).then(function (result) {
          resolve(result)
        }).catch(function (err) {
          console.error(name + "合约方法:" + func_name + "读取失败:参数如下", ...params);
          reject(err)
        });
      });
    };
    return contractInstant;
  },

  //每次钱包连接后的全局的一些数值的变化
  //array:{"icon","name","balance"}
  myAccount: [],



  // 切换链接网络
  switchNetChain: function () {
    const chain = rpc_config[ store.state.chainKey];

    return window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId: chain["net_id"],
        },
      ],
    });
  },
  // 是否链接网络
  isNetChain: function () {
    const chain = rpc_config[ store.state.chainKey];

    return parseInt(window.ethereum.networkVersion) === parseInt(chain["net_id"])
  },
  // 链接网络
  addNetChain: function () {
    const chain = rpc_config[ store.state.chainKey];
    return window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: chain["net_id"],
          chainName: chain["chain_name"],
          nativeCurrency: {
            name: chain["coin_unit"],
            symbol: chain["coin_unit"],
            decimals: 18,
          },
          rpcUrls: [chain["rpc_url"]],
          blockExplorerUrls: [chain["block_explorer_url"]],
        },
      ],
    });
  },
  //实时查询网络ID 1查看netid.链接网络
  checkNetId: function () {
    const chain = rpc_config[ store.state.chainKey];
    const that = this
    return new Promise(function (resolve, reject) {
      Web3Wallet.web3.eth.net.getId().then(function (netId) {
        if (netId != chain.net_id) { //网络变化
          that.switchNetChain().then((data) => {
            console.log("切换网路成功", data);
            resolve(true);
          })
            .catch((err) => {
              console.log("切换网路失败", err);
              if (err.code === 32601 || err.message.endsWith("does not exist/is not available")) {
                console.log("找不到该钱包", err);
              } else if (err.code === 4902) {
                that.addNetChain().then(() => {
                  console.log("添加网络成功", data);
                  resolve(true);
                })
                  .catch((err) => {
                    resolve(false);
                  })
              }
            });
        } else {
          resolve(false);
        }
      });
    });
  },
  // 切换网络
  // switchNetChain: function () {
  //   return window.ethereum.request({
  //     method: "wallet_switchEthereumChain",
  //     params: [{chainId: chain["net_id"]}],
  //   });
  // },
  // 添加网络
  // addNetChain: function () {
  //   console.log('添加网络')
  //   return window.ethereum.request({
  //     method: "wallet_addEthereumChain",
  //     params: [
  //       {
  //         chainId: chain["net_id"],
  //         chainName: chain["chain_name"],
  //         nativeCurrency: {
  //           name: chain["coin_unit"],
  //           symbol: chain["coin_unit"],
  //           decimals: 18,
  //         },
  //         rpcUrls: [chain["rpc_url"]],
  //       },
  //     ],
  //   });
  // },
  // 监听网络切换
  monitorNetChain: function (listener) {
    window.ethereum.on("networkChanged", (params) => {
      listener(params);
    });
  },
};

export default Web3Wallet
