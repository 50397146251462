<!--
* @Author: your name
* @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-12-23 19:04:51
 * @LastEditors: 叹息桥丶 648161532@qq.com
* @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
* @FilePath: \vue-portale:\demo\revoland\src\view\home.vue
-->
<template>
  <div class="news text-white text16">
    <div class="news_content">
      <div class="itemBox" >
        <div class="itemShadow"></div>
        <div class="itemWrapper">
          <div class="itemContent" :style="{ background:`url(${info.imagePc}) no-repeat center/100% auto` }"/>
        </div>
      </div>
      <div class="contentText ">
        <div class="contentTitle">{{info.title}}</div>
        <div class="contentDesc text-blue text-more4" v-if="$route.name=='noticeDetail' || $route.name=='newsDetail'" >{{moment(info.createTime).format("YYYY-MM-DD")}}</div>
      </div>
      <div class="contentDesc text-more" v-html="info.content"/>
    </div>
  </div>
</template>
<script>

import * as Api from "@/api/formal.js";

import moment from "moment";
export default {
  components: {},
  created() {
    this.getContent()
    this.$watch('$route.params.id', () => {
      this.getContent()
    })
    this.$watch('$store.state.lang', (newVal, oldVal) => {
      this.$router.back(-1);
    })
  },

  mounted() {},
  data() {
    return {
      list: [],
      info:{}
    }
  },
  computed:{},
  methods: {
    moment,
    getContent() {
      const { id } = this.$route.params
      Api.getDetail({ id }).then((res) => {
        console.log( res.data )
        this.info = res.data;
      });
    },
    
  },
};
</script>

<style lang="less" scoped>
.news{ position: relative;top: 0; left: 0; min-height: 100vh; width: 100%; background:url('/images/bg.jpg') repeat-y center top/ 100%}
.news_content {width:100%;min-height: 100vh;display: flex; flex-direction: column; grid-gap: 24px; padding:36px 20px;}
.itemBox{ 
  position:relative;
  height:220px;
  .itemShadow  { 
    height:100%;
    border-radius: 10px;border: 2px solid rgba(255, 137, 99, 0.25);width:100%;position:relative;
    &::before{ content:'';display: block; width:20px;height:5px;z-index:1;position: absolute; bottom: -3px; left: calc( 50% - 10px );background-color:#0F2841; }
  }
  .itemWrapper{ 
    height: calc( 100% - 12px);
    border-radius: 10px;border: 2px solid #FF8963;width:100%;position:absolute; top:0px; left:0px; 
    &::after{ content:'';display: block; width:100%;height:100%;position: absolute; bottom: 0; left: 0;z-index:3;box-shadow: 0px 1px 30px 0px rgba(255, 137, 99, 0.40) inset}
    &::before{ content:'';display: block; width:20px;height:5px;position: absolute; bottom: -5px; left: calc( 50% - 10px );z-index:1;background-color:#0F2841;}
  }
  .itemContent{ 
    position:absolute; top:0px; left:0px;z-index:2;
    width:100%;height:100%;background:#102C4B;border-radius: 10px;
    filter: drop-shadow(0px 0px 10px rgba(255, 137, 99, 0.40));
    .contentImg{ width:100%;aspect-ratio:1000/580; box-shadow: 0px -20px 20px 0px #102C4B inset; img{ width:100%;height:100% } 
      border-radius: 10px;
    };
  }
}
.contentText{ display:flex; flex-direction:column; gap:12px;text-align:center };
.contentDesc{ font-size:12px }
.contentTitle{ font-size:16px }


@media screen and (min-width: 1080px) {
  .news{ background:url('/images/bg_pc.jpg') repeat-y center top/ 100%}
  .news_content {padding:60px 0 0; max-width: 960px; margin:0 auto;gap:60px}
  .itemBox{ height: 571px;flex-shrink: 0;}
  .contentText{ display:flex; flex-direction:column; gap:12px;text-align:center };
  .contentDesc{ font-size:20px }
  .contentTitle{ font-size:28px }
}
</style>
  