<template>
  <div class="myido text-white text16">
    <div class="myido_content" >
      <div class="content_info">
        <div class="content_title">{{$t("home.menu.course")}}</div>
        <div class="content_desc text-more"  v-if="about.subTitle">{{about.subTitle}}</div>
      </div>
      <!-- <div class="stepsBox" v-if="about.content"> 
        <div class="boxLine"></div>
        <div class="boxItem" v-for="(item,key) in about.content" :key="key">
          <div class="itemTime">{{item.date}}</div>
          <div class="itemContent  text-more">{{item.content}}</div>
        </div>
      </div> -->
      <div class="section ql-editor" v-html="about.brief"></div>
    </div>
  </div>
</template>
<script>
import * as Api from "@/api/formal.js";

export default {
  components: {},
  computed: {},
  created() {
    this.getList();
    this.$watch('$store.state.lang', (newVal, oldVal) => {
      this.getList();
    })
  },
  mounted() {},
  data() {
    return {
      about: {},
    };
  },
  methods: {
    getList() {
      Api.getAbout({ type:1 }).then((res) => {
        this.about = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myido{ position: relative;top: 0; left: 0; min-height: 100vh; width: 100%; background:url('/images/bg.jpg') repeat-y center top/ 100%}
.myido_content {width:100%;min-height: 100vh;display: flex; flex-direction: column; grid-gap: 24px; padding:36px 20px; }
.content_info{ text-align:center;
    display:flex;flex-direction:column; gap:24px;
    .content_title{ font-size:20px };
    .content_desc{ font-size:12px };
  }

/deep/.section{ 
  overflow:hideen;
  img { max-width:100% }
}
.section2{ 
  position:relative;
  .itemShadow  { 
    border-radius: 10px;border: 2px solid rgba(255, 137, 99, 0.25);width:100%;aspect-ratio:1000/600;position:relative;
    &::before{ content:'';display: block; width:20px;height:5px;z-index:1;position: absolute; bottom: -3px; left: calc( 50% - 10px );background-color:#0F2841; }
  }
  .itemWrapper{ 
    border-radius: 10px;border: 2px solid #FF8963;width:100%;aspect-ratio:1000/580;;position:absolute; top:0px; left:0px; 
    &::after{ content:'';display: block; width:100%;height:100%;position: absolute; bottom: 0; left: 0;z-index:3;box-shadow: 0px 1px 30px 0px rgba(255, 137, 99, 0.40) inset}
    &::before{ content:'';display: block; width:20px;height:5px;position: absolute; bottom: -5px; left: calc( 50% - 10px );z-index:1;background-color:#0F2841;}
  }
  .itemContent{ 
    position:absolute; top:0px; left:0px;z-index:2;
    width:100%;height:100%;background:#102C4B;border-radius: 10px;
    filter: drop-shadow(0px 0px 10px rgba(255, 137, 99, 0.40));
  }
}

.stepsBox{ 
  position:relative;top:0;left:0; 
  // height:300px;
  display:flex; flex-direction:column; gap:48px;
  .boxLine{ 
    border-radius: 4px;background: #FF8963;box-shadow: 0px 0px 4px 0px #FF8963; width: 4px;height: 100%;flex-shrink: 0;
    position:absolute; left:calc((100% - 4px)/2);
    &::before{ 
      content:'';border-radius:50%;
      position:absolute; left:calc((100% - 20px)/2); bottom:-8px;
      height:12px;width:12px; background-color:#fff;
      border:4px solid  #FF8963;
      box-shadow: 0px 0px 4px 0px #FF8963;
    }
  }
  .boxItem{ display:flex; justify-content:space-between; align-items:center; gap:50px ;
    .itemTime{ 
      font-size: 20px;text-align:right;color:  #FF8963;
      text-shadow: 0px 0px 4px rgba(255, 137, 99, 0.50);
      font-weight: 700;
      letter-spacing: 0.3px;
    }
    .itemContent{ font-size: 12px; }
  }
  .boxItem>div{ flex:1;flex-shrink:0 };
}

@media screen and (min-width: 1080px) {
  .myido{ background:url('/images/bg_pc.jpg') repeat-y center top/ 100%}
  .myido_content {padding:60px 0 0; max-width: 960px; margin:0 auto;gap:60px}
  .content_info{ gap:60px;
    .content_title{ font-size:48px };
    .content_desc{ font-size:20px };
  }
  .stepsBox{ width:70% ;margin:0 auto;
    .boxItem{ 
      .itemTime{ font-size: 28px;}
      .itemContent{ font-size: 20px; text-align:left; }
    }
  }

}
</style>
