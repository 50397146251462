/*
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-04-11 10:43:17
 * @LastEditTime: 2023-12-23 19:43:26
 * @LastEditors: 叹息桥丶 648161532@qq.com
 */
import Vue from "vue";
import Vuex from "vuex";
import {langs} from "../locals/langs";
import { imgsPreloader } from '../utils/imgPreloader/imgPreloader.js';
import { assets } from "../utils/imgPreloader/config.js";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    lang: localStorage.getItem("lang")||"en-us",
    Web3Wallet: null,
    walletAddress: "",
    userToken:null,

    drawer:false,
    isMobile:false,
    
    chainKey:'bsc-test',
    block:{}
  },
  getters: {
    getWeb3js(state) {
      return state.Web3Wallet;
    },
    getWalletAddress(state) {
      return state.walletAddress;
    },
    getDrawer(state){ 
      return state.drawer
    },
    getLang(state){ 
      return state.lang
    }
  },
  mutations: {
    async updateLang(state, lang) {
      //检查是否在翻译, 有的话切换,没的话忽略
      if (langs.includes(lang)) {
        state.lang = lang;
        localStorage.setItem("lang",lang);
      }
    },
    updateBlock(state,block) {
      state.block = block;
    },
    updateDrawer(state) {
      state.drawer = !state.drawer;
    },
    setWe3Js(state, object) {
      state.web3js = object;
    },
    setMaskAccount(state, account) {
      if(account){ 
        state.walletAddress = account;
        localStorage.setItem('walletAddress', account)
        return false
      }
      state.walletAddress = null;
      localStorage.removeItem('walletAddress');
    },
    setToken(state, account) {
      if(account){ 
        state.userToken = account;
        localStorage.setItem('userToken', account)
        return false
      }
      state.userToken = null;
      localStorage.removeItem('userToken');
    },

    // userToken
  },
  actions: {
    logout ({ commit }) {
      commit('setToken');
      commit('setMaskAccount');
    },

    setWallet ({ commit }, web3Wallet) {
      commit('web3Wallet', web3Wallet)
    },
    setWalletAddress ({ commit }, address) {
      if(address){ 
        commit('setMaskAccount', address)
      }else{ 
        commit('setMaskAccount', localStorage.getItem('walletAddress'))
      }
    },

    setLang( { commit }, lang  ){ 
      commit('updateLang', lang)
    },

    setUserToken ({ commit }, token) {
      if(token){ 
        commit('setToken', token)
      }else{ 
        commit('setToken', localStorage.getItem('userToken'))
      }
    },
  },
  modules: {},
});
