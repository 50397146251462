/*
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-04-11 10:43:17
 * @LastEditTime: 2023-12-28 15:12:41
 * @LastEditors: 叹息桥丶 648161532@qq.com
 */
import Vue from "vue";
import { Dropdown, DropdownMenu, DropdownItem, Drawer, Notification, Message, Steps,Step,InfiniteScroll, Carousel, CarouselItem,Menu,MenuItem,Row,Col ,Card,Tooltip,Dialog,Button,MessageBox,Loading,Submenu,MenuItemGroup} from "element-ui";
import { imgsPreloader } from './utils/imgPreloader/imgPreloader.js';
import "./assets/less/element-variables.scss";
import "element-ui/lib/theme-chalk/display.css";

import './utils/filter' // global filter
import { i18n } from "./locals";
import 'swiper/dist/css/swiper.min.css'
import './assets/less/quill.bubble.css'

import 'swiper/dist/js/swiper.min'

import env from "./config/env.js";
import axios from "axios";
import qs from "qs";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyload from "vue-lazyload";

Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Drawer);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(MenuItemGroup);
Vue.use(Steps);
Vue.use(Step);
Vue.use(InfiniteScroll);


Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Tooltip);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Loading);


Vue.use(VueLazyload, {
  preLoad: 1.5,
  error: '../src/assets/image/error.png',
  loading: '../src/assets/image/loading.gif',
  attempt: 1
})

i18n.locale = store.getters.getLang;

Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$ajax = axios;
Vue.prototype.$qs = qs;
Vue.config.productionTip = false;
Vue.prototype.$env = env;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$loading = Loading;



import CommonMix from "@/mixin/CommonMix"; //通用 mixin
Vue.mixin(CommonMix)


export default (async () => {
  //预加载
  // let time = window.setTimeout(function(){ 
      // await imgsPreloader();
  //   window,clearTimeout(time)
  // },1000)
  document.getElementById('loader-wrapper').style.display="none"
  //let images =  console.log(images)
  //关闭加载弹框
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app');
})()






