/*
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-08-15 19:00:38
 * @LastEditTime: 2023-12-28 16:57:05
 * @LastEditors: 叹息桥丶 648161532@qq.com
 */
import axios from 'axios'
import store from '@/store'
import Vue from 'vue'

// import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { signatureStr } from './signature'
import qs from 'qs'


// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  // baseURL: "https://t18.crydit.vip/",
  baseURL: "https://gw-api.newworld.vip/",
  timeout: 6000, // 请求超时时间
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    // const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) { }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {}
  }
  return Promise.reject(error)
}

// request interceptor 请求拦截
request.interceptors.request.use(config => {
  let language = store.state.lang
  const lang = language === 'zh-cn'?'zh-HK':'En'
  if ( config.method.toLocaleLowerCase()  === 'post'  ) {
    const data = {...config.data,lang}
    config.data = qs.stringify(data) 
  } else if (config.method.toLocaleLowerCase() === 'get') {
    const params = {...config.params,lang}
    config.params = params
  }
  
  return config
}, errorHandler)

// response interceptor响应拦截
request.interceptors.response.use((response) => {
  if(response.data.data === 14007 ){ 
    //  身份过期
    // store.dispatch('logout')
    new Vue().$router.push('/')
    return false
  }
  return response.data
}, errorHandler)


const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
