/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-08-01 11:41:33
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-22 18:06:49
 * @FilePath: \do2f:\Workspace\TechMaker\src\locals\zh-cn\home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    home:{ 
        btn:"About Us",
        detail:"Detail",
    },
    serve:{ 
        title:"NewWorld Ecology",
        btn:"More Ecology"
    },
    updates:{ 
        title:"Latest Updates",
        btn:"View More"
    },
    menu:{ 
        home:"Home",
        about:"About Us",
        serve:"NewWorld Ecology",
            pool:"Production Pool",
            battle:"Space Battlefield",
            ecology:"NFT Ecology",
            more:"More",
        updates:"Latest Updates",
            news:"News",
            notice:"Announcements",
        course:"Roadmap",
    }
}
      
    
    