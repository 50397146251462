/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-08-01 17:27:54
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-21 20:48:00
 * @FilePath: \do2f:\Workspace\TechMaker\src\locals\zh-cn\community.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    logo:'/images/logo.svg',
    message: "敬請期待！",
    locals:[
        { icon:'/images/locals/en-us.svg',key:'en-us',title:'English' },
        { icon:'/images/locals/zh-cn.svg',key:'zh-cn',title:'繁體中文' },
        // { icon:'/images/locals/ja-jp.svg',key:'ja-jp',title:'日本語' },
        // { icon:'/images/locals/ko-kr.svg',key:'ko-kr',title:'한국어' }
    ],
    chain:[
        { icon:'/images/chain/BNB.png',key:'bsc-test' },
        { icon:'/images/chain/Arbitrum.svg',key:'Arbitrum' },
        { icon:'/images/chain/BNB.png',key:'BNB' },
        { icon:'/images/chain/ETH.svg',key:'ETH' }
    ],

    navList:[
        {title:"Home",href:"#home",name:''},
        {title:"Crydit Card",href:"#card",name:''},
        {title:"Using",href:"#using",name:''},
    ], 

    bottom_link1:[ 
        { image:'/images/svg/F4 (1).svg',link:"https://medium.com/@Revolutionland"},
        { image:'/images/svg/F4 (2).svg',link:"https://discord.gg/GtHAQqHy6y"},
        { image:'/images/svg/F4 (4).svg',link:"https://twitter.com/Revolutionland"},
    ],
    
    footer:{ 
        statement:{ 
            'service':{ title:'服务条款'},
            'policy':{title:'隐私政策'},
            'contact':{title:'联系我们'},
            'rights':{title:'© 2023 Revolution Land. 保留所有权利'},
        }
    }
}
      
    
    