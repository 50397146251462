/*
 * @Author: 叹息桥
 * @Description: 操作记录
 * @Date: 2022-08-15 19:00:38
 * @LastEditTime: 2023-08-31 10:55:58
 * @LastEditors: 叹息桥丶 648161532@qq.com
 */
import request from '@/utils/request'
import store from '@/store';
    



/**
 * @description: 活动历史记录
 * @return {*}
 * @param {*} parameter
 */
 
export function getNonce(parameter) {
    return request({url: '/api/portal/sso/nonce',method: 'get',params: parameter})
}

export function login(parameter) {
    return request({url: '/api/portal/sso/login',method: 'post',data: parameter})
}

export function getList(parameter) {
    return request({url: '/api/ido/list',method: 'post',data:{   
        ...parameter,
        blockType:store.state.chainKey  
    }})
}

export function getInfo(parameter) {
    return request({url: '/api/ido/detail',method: 'post',data: {   
        ...parameter,
        blockType:store.state.chainKey  
    }})
}

export function getMyList(parameter) {
    return request({url: '/api/portal/ido/list',method: 'post',data: {   
        ...parameter,
        blockType:store.state.chainKey  
    }})
}












