<template>
  <div class="header">
    <div class="header-content">
      <div class="handle" v-if="$store.state.isMobile">
        <img class="icon" v-if="$store.state.drawer" @click="openDrawer" src="/images/bg/close.png" />
        <img class="icon" v-else @click="openDrawer" src="/images/bg/menu-btn.png" />
      </div>

      <img class="logo text-bold" src="/images/logo.png" @click="$router.push('/')"/>
      <div  class="menu">
        <el-menu
          default-active="2" class="menu" mode="horizontal"
          @select="handleSelect"
          background-color="transparent" text-color="#fff" active-text-color="#fff"
        >
          <el-menu-item index="home" >
            <template slot="title">{{$t("home.menu.home")}}</template>
          </el-menu-item>
          <el-menu-item index="about" >
            <template slot="title">{{$t("home.menu.about")}}</template>
          </el-menu-item>
          <el-submenu index="ecology">
            <span slot="title">{{$t("home.menu.serve")}}</span>
            <el-menu-item :index="item.id" v-for="(item,key) in nav" :key="key">
              <template slot="title">{{item.title }}</template>
            </el-menu-item>
            <el-menu-item index="serve" >
              <template slot="title">{{$t("home.menu.more")}}</template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="active" >
            <span slot="title">{{$t("home.menu.updates")}}</span>
            <el-menu-item index="news">
              <template slot="title">{{$t("home.menu.news")}}</template>
            </el-menu-item>
            <el-menu-item index="notice">
              <template slot="title">{{$t("home.menu.notice")}}</template>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="course">
            <span slot="title">{{$t("home.menu.course")}}</span>
          </el-menu-item>
        </el-menu>
      </div>

      <div class="handle">
        <el-dropdown @command="changeLanguage">
          <div class="language_btn">
            <img class="icon" src="/images/bg/language-btn.svg" />
            <span>{{ getValue(languages,"key",$store.state.lang)["title"] }}</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, key) in languages"
              :key="key"
              :command="item.key"
            >
              {{ item.title }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "@/api/formal.js";
import { getValue } from "@/utils/dict";
export default {
  props:{
    info: { type: [Array], default: function () {return []}}
  },
  data() {
    const that = this;
    return {
      nav:[],
      languages: that.$t("common.locals"),
      chain: that.$t("common.chain"),
    };
  },
  created() {
    // this.getMenu()
    // this.$watch('$store.state.lang', (newVal, oldVal) => {
    //   this.getMenu()
    // })
    this.$watch('info', (newVal, oldVal) => {
      this.nav = newVal;
    },{deep:true})
  },
  mounted() {},
  methods: {
    getValue,
    // 下拉框选择语言
    changeLanguage(item) {
      if (item === this.$store.getters.getLang) {
        return;
      }
      this.$store.dispatch('setLang',item)
    },
    changeChain(item) {
      if (item === this.$store.getters.getLang) {
        return;
      }
      this.$store.state.chainKey = item;
    },
    
    // getMenu(){
    //   Api.getNav({}).then(res => {
    //     console.log( res.data )
    //     this.nav = res.data
    //   })
    // },

    handleSelect(key, keyPath) {
      if(keyPath[0] == "ecology" && keyPath[1] !== "serve"){ 
        this.$router.push({ path:"/serve/detail/"+keyPath[1] })
      }else{ 
        this.$router.push({ name:key,params:{}})
      }
    },
   

    openDrawer() {
      console.log(this.$store.state.drawer);
      this.$store.state.drawer = !this.$store.state.drawer;
    },
   
  },
};
</script>
<style lang="less" scoped>
// flex-shrink:0;
.header {position: fixed;top: 0;left: 0;height: 80px;width: 100%;z-index: 1024;background: url("/images/bg.jpg") no-repeat ; 
  background-position: center calc(100% - 15px);
  background-size: 100% auto;
}
.header .header-content {display: flex;align-items: center;justify-content: space-between;height: 100%;font-size: 24px;padding: 8px 0.15rem 16px;background: url(/images/bg/header-bg.png) no-repeat center bottom/100% 24px;}
.handle,.language_btn {
  display: flex;align-items: center; gap:4px;
  color:#fff;cursor: pointer;
  span{ display:none}
  .icon {height: 24px;width: 24px;}
}
.logo{ height:30px; width:auto; cursor: pointer;}

/deep/.menu{ 
  position:relative;border:unset; 
  .el-menu-item,.el-submenu__title{ background: transparent !important; border:unset !important }
  .is-active{ 
    &.el-menu-item,.el-submenu__title { background-color:transparent !important ; border:unset !important;};
  }
  .el-submenu{ 
    .el-submenu__title{ background: transparent !important; }
  }
  .el-menu .el-menu-item,.el-submenu__title{ font-size:16px; }
  .el-submenu .el-menu-item{ font-size:16px; }
  .el-submenu__title i{ color:#fff; }
}

.el-dropdown-menu{ 
  background-color:#ced6ed ;
  color:#0A243D ;
  /deep/.popper__arrow{ 
    display:none;
  }
  .el-dropdown-menu__item{ 
    color:#0A243D;font-weight:700;
    background:unset;
  }
}

.menu{ display:none }


@media screen and (min-width: 1080px) {
  .header {background: url("/images/bg_pc.jpg") no-repeat;background-position: center calc(100% - 15px);background-size: 100% auto;}
  .header .header-content {
    font-size: 32px; gap:80px;justify-content:flex-start;
    padding: 12px 40px 24px;
  }
  .handle {
    .language_btn{ font-size:14px; span{ display:block}}
  }
  /deep/.menu{ 
    display:block;margin-right:auto ;
    .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow{ margin-top:0  }
    .el-submenu__title,.el-menu-item{ display:flex;align-items:center;}
  }

  .logo{ height:36px; width:auto; }
}
</style>
<style>
  .el-menu--popup{ 
    background-color:#ced6ed !important;
    .el-menu-item{ color:#0A243D !important;font-weight:700; background: transparent !important; }
  }
</style>
