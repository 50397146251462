/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-08-08 14:21:26
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-23 14:21:36
 * @FilePath: \do2f:\Workspace\TechMaker\src\utils\filter.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import moment from 'moment'
// import 'moment/locale/zh-cn'
// moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})


Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('timeFormat', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr*1000).format(pattern)
})

Vue.filter('WalletAccount', function (value) {
  if (!value) {
    return '0'
  } 
  const str = value.slice(0, 4);
  const str1 = value.substr(-4);
  return str + "***" + str1;
})

Vue.filter('dissTime', function (value) {
  if (!value || value<0 || value == undefined ) {
    return '0:00:00'
  }
  var hour = parseInt((value / 3600)); //获取还剩多少小时
  var minute = parseInt((value / 60) % 60);//获取还剩多少分钟
  var second = value % 60; //获取还剩多少秒
  //输出还剩多少时间
  return (hour>0?hour+':':'0:') + (minute>0?minute+':':'00:') + (second>0?second:'00')
})

