/*
 * @Author: 叹息桥
 * @Description: 操作记录
 * @Date: 2022-08-15 19:00:38
 * @LastEditTime: 2023-12-25 11:59:55
 * @LastEditors: 叹息桥丶 648161532@qq.com
 */
import request from '@/utils/request'


/* 首页轮播*/
export function getBanner(parameter) {
    return request({url: '/api/portal/unifyInterface/carousel',method: 'post',data: parameter})
}
/* 首页*/
export function getHome(parameter) {
    return request({url: '/api/portal/unifyInterface/homePage',method: 'post',data: parameter})
}

/* 导航链接 */
export function getNav(parameter) {
    return request({url: '/api/portal/unifyInterface/menuEcology',method: 'post',data: parameter})
}

/* 页脚链接 */
export function getFooter(parameter) {
    return request({url: '/api/portal/unifyInterface/outChain',method: 'post',data: parameter})
}

/* 首页轮播 type： 1：生态 2：最新动态-新闻  3：最新动态-公告*/
export function getList(parameter) {
    return request({url: '/api/portal/unifyInterface/ecology',method: 'post',data: parameter})
}

export function getContent(parameter) {
    return request({url: '/api/portal/unifyInterface/articleCate',method: 'post',data: parameter})
}

/* 首页轮播 type 0：路线图 1：关于我们*/
export function getAbout(parameter) {
    return request({url: '/api/portal/unifyInterface/aboutData',method: 'post',data: parameter})
}

export function getDetail(parameter) {
    return request({url: '/api/portal/unifyInterface/articleDetail',method: 'post',data: parameter})
}






