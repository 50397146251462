/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-01-28 14:44:03
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-02-06 16:28:15
 * @FilePath: \Workspace\revoland\revoland-extension\src\locals\en.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import home from './en-us/home.js'
import common from './en-us/common.js'
export default {
  common,
  home
}