<!--
 * @Author: your name
 * @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-12-25 14:16:20
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue-portale:\demo\revoland\src\view\home.vue
-->
<template>
  <div class="drawer">
    <!--  v-if="info.header" -->
    <el-drawer :visible.sync="$store.state.drawer" direction="ltr" :before-close="handleClose" size="100%">
    <Header/>
    <div class="bodyer">
    <el-menu default-active="2" class="el-menu-vertical-demo" unique-opened
    @open="handleOpen" @close="handleClose"  @select="handleSelect"
     background-color="transparent" text-color="#fff" active-text-color="#0A243D">
      <el-menu-item index="home" >
        <template slot="title">{{$t("home.menu.home")}}</template>
      </el-menu-item>
      <el-menu-item index="about" >
        <template slot="title">{{$t("home.menu.about")}}</template>
      </el-menu-item>
      <el-submenu index="ecology">
        <span slot="title">{{$t("home.menu.serve")}}</span>
        <el-menu-item :index="item.id" v-for="(item,key) in nav" :key="key">
          <template slot="title" >{{item.title }}</template>
        </el-menu-item>
        <el-menu-item index="serve" >
          <template slot="title">{{$t("home.menu.more")}}</template>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="active" >
        <span slot="title">{{$t("home.menu.updates")}}</span>
        <el-menu-item index="news">
          <template slot="title">{{$t("home.menu.news")}}</template>
        </el-menu-item>
        <el-menu-item index="notice">
          <template slot="title">{{$t("home.menu.notice")}}</template>
        </el-menu-item>
      </el-submenu>
      <el-menu-item index="course">
        <span slot="title">{{$t("home.menu.course")}}</span>
      </el-menu-item>
    </el-menu>
    </div>
    <Footer/>
    </el-drawer>
  </div>
</template>
<script>
import { getValue } from '@/utils/dict';
import Header from "@/components/header";
import Footer from "@/components/footer"; //页脚
import * as Api from '@/api/formal.js'


export default {
  name: "Drawer",
  components: {
    Header,
    Footer
  },
  props:{
    info: { type: [Array], default: function () {return []}}
  },
  data() {
    return {
      languages: this.$t('common.locals'),
      chain: this.$t('common.chain'),
      nav:[]
    };
  },
  created(){
    this.$watch('info', (newVal, oldVal) => {
      this.nav = newVal;
    },{deep:true})
  },
 
  methods: {
    getMenu(){
      Api.getNav({}).then(res => {
        this.nav = res.data
      })
    },
    
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },

    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    handleSelect(key, keyPath) {
      // console.log( key, keyPath )
      if(keyPath[0] == "ecology" && keyPath[1] !== "serve"){ 
        this.$router.push({ path:"/serve/detail/"+keyPath[1] })
      }else{ 
        this.$router.push({ name:key,params:{}})
      }
      this.$store.commit("updateDrawer");
    },
  },
};
</script>

<style>
  .el-carousel__indicator.is-active button{ background-color:#FF8963 }
</style>

<style lang="less" scoped>

/deep/.el-drawer {
  .el-drawer__header { margin:0 ;display:none}
  .el-drawer__body{ 
    display:flex;flex-direction:column;
    background:url('/images/bg.jpg') repeat-y center / 100%;
  }
  .bodyer{ 
    padding-top:80px;
    flex:1;
  }

  .el-menu{ 

    .el-menu-item,
    .el-submenu__title{ background: transparent !important ;height:80px;line-height:80px;font-size:24px; font-weight:700!important; }

    .el-submenu .el-menu-item{ 
      height:40px;line-height:40px;font-size:16px;  font-weight:400   
    }

    .is-active{ 
      &.el-menu-item{ background-color:#83A0C2 !important;};
      // .el-submenu__title{ background-color:#83A0C2 !important;};
      // .el-submenu__title{ background-color:#83A0C2 !important};
    }
    
    .el-submenu__title i{ 
      color: #FFFFFF;font-size:16px;
    }
    
   



  }
 
}
</style>