/*
 * @Author: 叹息桥
 * @Description: 
 * @Date: 2022-05-06 15:09:43
 * @LastEditTime: 2023-12-21 20:51:50
 * @LastEditors: 叹息桥丶 648161532@qq.com
 */
import Vue from "vue";
import VueI18n from 'vue-i18n'
import { langs } from "./langs";
const messages = {}
langs.forEach((lang) => {
  messages[lang] = require(`./${lang}.js`).default;
});

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: "zh-cn", // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
  fallbackLocale: "zh-cn",
  messages,
});

export function $t(args) {
  return i18n.tc.call(i18n, args);
}

