<!--
 * @Author: your name
 * @Date: 2022-04-11 10:38:20
 * @LastEditTime: 2023-12-25 12:30:56
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \revlandPresalef:\workSpace\website\src\App.vue
-->
<template>
  <div id="app" >
    <!-- :class="[!isMobile ? 'pc' : 'app']" -->
    <Header :info="nav"/>
    <router-view  id="main"/>
    <Footer :info="footer"/>
    <Drawer :info="nav"/>
  </div>
</template>
<script>
 
import Header from "@/components/header";
import Drawer from "@/components/drawer";
import WalletHandler from "@/mixin/WalletHandler";
import Footer from "@/components/footer"; //页脚
import * as Api from "@/api/formal.js";

export default {
  mixins: [WalletHandler],
  name: "App",
  components: {
    Header,
    Drawer,
    Footer
  },
  mounted() {
  },
  data() {
    return {
      nav:[],
      footer:[],
    };
  },
  async created() {
    const that = this
    this.resizeWidth();
    this.getMenu()
    this.getFooter()
    // 监听语言变更
    this.$watch('$store.state.lang', (newVal, oldVal) => {
      console.log('语言包更新，当前语言：',newVal,' 更新数据')
      this.$i18n.locale = newVal;
      this.getMenu()
    })
    window.addEventListener('resize',function(){
      that.resizeWidth()
    },false);
  },
  methods:{
    resizeWidth(){ 
      const clientWidth = `${document.documentElement.clientWidth}`;
      this.$store.state.isMobile = clientWidth < 1080
      const fz = (100 * clientWidth) / 375
      const docEl = window.document.documentElement
      docEl.style.fontSize = (fz<500?fz:500) + 'px'
    },
    getMenu(){
      Api.getNav({}).then(res => {
        this.nav = res.data
      })
    },
    getFooter(){
      Api.getFooter({}).then(res => {
        this.footer = res.data
      })
    },
  },
  computed: {
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
    },
  },
}
</script>

<style lang="less">
@import "assets/less/index.css";
@import "assets/less/animation.css";
@import "assets/less/animate.css";
@import "assets/less/font.css";
#main{ 
    min-height: 100vh;
    margin-top:65px;
    padding-bottom: 80px;
}
</style>

