<!--
 * @Author: your name
 * @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-12-25 12:33:09
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \revlandPresalee:\demo\revoland\src\components\footer.vue
-->
<template>
  <div class="footer border-line">
    <div class="content ">
      <div class="box">
        <div class="flex align-center justify-center gap24 ">
          <a class="icon" :href="item.url" v-for="(item,key) in icons" :key="key">
            <img :src="item.image" />
          </a>
        </div>
        <img class="logo text-bold" src="/images/logo.png"  @click="$router.push('/')"/>
      </div>
      <div class="copyright text12 text-center text-white">
        <div>NewWorld © & TM 2024 Lucasfilm Ltd. </div>
        <div>Developed by NewWorld. </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "@/api/formal.js";

export default {
  props:{
    info: { type: [Array], default: function () {return []}}
  },
  computed: {
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
    },
  },
  created(){
    // this.getIcons();
    this.$watch('info', (newVal, oldVal) => {
      this.icons = newVal;
    },{deep:true})
  },
  data() {
    return { 
      icons:[]
    };
  },
  methods: {
    // 默认页面
    // getIcons(){
    //   Api.getFooter({}).then(res => {
    //     this.icons = res.data
    //   })
    // },
  },
};
</script>
<style lang="less" scoped>
// background: #15181D;
.footer { width: 100%;padding: 12px 20px; background: #233954;}
.footer .content {  display: flex; flex-direction:column;gap:24px; }
.box {  display: flex; flex-direction:column;gap:24px; align-items:center; }
.logo{ height:50px;width:auto; cursor: pointer;}
.icon{height:24px;width:24px; img{ height:100%;width:100%; } }
.copyright{ color:#83a0c2 }
@media screen and (min-width: 1080px) {
  .footer { padding: 0px 40px; }
  .footer .content { gap:0px; }
  .logo{ height:36px }
  .box {  display: flex; flex-direction: row-reverse;justify-content:space-between;padding:24px 0}
  .copyright{ border-top:1px solid rgba(255, 255, 255, 0.10) ;padding:12px 0; display:flex; gap:1em }
}
</style>