<!--
    * @Author: your name
    * @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-12-23 16:06:16
 * @LastEditors: 叹息桥丶 648161532@qq.com
    * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
    * @FilePath: \vue-portale:\demo\revoland\src\view\home.vue
   -->
<template>
  <div class="news text-white text16">
    <div class="news_content">
      <div class="content_info">
        <div class="content_title">{{$t("home.menu.serve")}}</div>
        <div class="content_desc text-more" v-if="info.brief">{{info.brief}}</div>
      </div>
      <div class="box" v-for="(item,key) in list" :key="key" @click="linkDetail(item.id)">
        <div class="itemBox" >
          <div class="itemShadow"></div>
          <div class="itemWrapper">
            <div class="itemContent" >
              <div class="contentImg" :style="{ backgroundImage:`url(${item.imagePc})` }" />
              <div class="contentText">
                <div class="contentTitle">{{item.title}}</div>
                <div class="contentDesc text-more4">{{item.brief}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import * as Api from "@/api/formal.js";
export default {
  components: {},
  created() {
    this.getList()
    this.getContent()
    this.$watch('$store.state.lang', (newVal, oldVal) => {
      this.getList()
      this.getContent()
    })
  },
 
  mounted() { },
  data() {
    return {
      list: [],
      info:[]
    }
  },
  computed: {
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
    },
   
  },
  methods: {
    getList() {
      Api.getList({ type: 1 }).then((res) => {
        console.log(res.data.list )
        this.list = res.data.list;
      });
    },
    getContent() {
      Api.getContent({ type: 1 }).then((res) => {
        console.log(res.data)
        this.info = res.data;
      });
    },
    linkDetail(id){ 
      this.$router.push({ path: "/serve/detail/"+id })
      // this.$router.push({name:'noticeDetail',params:{id}})
    }
  },
};
</script>

<style lang="less" scoped>
.news{ position: relative;top: 0; left: 0; min-height: 100vh; width: 100%; background:url('/images/bg.jpg') repeat-y center top/ 100%}
.news_content {width:100%;min-height: 100vh;display: flex; flex-direction: column; grid-gap: 24px; padding:36px 20px;text-align:center }

.content_info{ text-align:center;
  display:flex;flex-direction:column; gap:24px;
  .content_title{ font-size:20px };
  .content_desc{ font-size:12px };
}

.itemBox{ 
  position:relative;
  height:420px;
  cursor: pointer;
  
  .itemShadow  { 
    height:100%;
    border-radius: 10px;border: 2px solid rgba(255, 137, 99, 0.25);width:100%;position:relative;
    &::before{ content:'';display: block; width:20px;height:5px;z-index:1;position: absolute; bottom: -3px; left: calc( 50% - 10px );background-color:#0F2841; }
  }
  .itemWrapper{ 
    height: calc(100% - 12px);
    border-radius: 10px;border: 2px solid #FF8963;width:100%;position:absolute; top:0px; left:0px; 
    &::after{ content:'';display: block; width:100%;height:100%;position: absolute; bottom: 0; left: 0;z-index:3;box-shadow: 0px 1px 30px 0px rgba(255, 137, 99, 0.40) inset}
    &::before{ content:'';display: block; width:20px;height:5px;position: absolute; bottom: -5px; left: calc( 50% - 10px );z-index:1;background-color:#0F2841;}
  }
  .itemContent{ 
    position:absolute; top:0px; left:0px;z-index:2;
    width:100%;height:100%;background:#102C4B;border-radius: 10px;
    filter: drop-shadow(0px 0px 10px rgba(255, 137, 99, 0.40));
    display:flex;flex-direction:column;
    .contentImg{ width:100%;aspect-ratio:1000/580; box-shadow: 0px -20px 20px 0px #102C4B inset; img{ width:100%;height:100% } 
      background-repeat:no-repeat;
      background-position:center;
      background-size:100% auto;
      border-radius: 10px;
      flex:1;
    };
    .contentText{ padding:24px ;display:flex; flex-direction:column; gap:24px; height:200px  };
    .contentDesc{ font-size:12px }
    .contentTitle{ font-size:16px }
  }
}


@media screen and (min-width: 1080px) {
  .news{ background:url('/images/bg_pc.jpg') repeat-y center top/ 100%}
  .news_content{ padding:60px 0 0; max-width: 1400px; margin:0 auto;gap:60px }
  .content_info{ gap:60px;
    .content_title{ font-size:48px };
    .content_desc{ font-size:20px };
  }
  .itemBox{ 
    height:320px;
    .itemContent{ 
      flex-direction:row;
      .contentImg{ width:100%;aspect-ratio:unset; box-shadow: -20px 0px 20px 0px #102C4B inset; flex-basis: 15% !important; background-size: 100% auto;};
      .contentText{ justify-content:center;flex:1; height:unset;}
      .contentTitle{ font-size:28px }
      .contentDesc{ font-size:20px }
    }
  }
  .box:nth-child(2n+1) .itemBox{ 
    .itemContent  { 
      flex-direction:row-reverse;
      .contentImg{box-shadow: 20px 0px 20px 0px #102C4B inset;};
    }
  }

}
</style>
