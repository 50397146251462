<!--
 * @Author: your name
 * @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-12-25 12:00:59
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue-portale:\demo\revoland\src\view\home.vue
-->
<template>
  <div class="home text-white text16">
    <div class="home_content" >
      <div class="section1 content_wrapper" ref="ccc">
        <el-carousel indicator-position="outside" :height="homeCarousel+'px'" >
          <el-carousel-item v-for="(item,key) in banner" :key="key">
          <a class="item" :href="item.url||'javascript:;'" :style="{ background: $store.state.isMobile? `url(${ item.imageApp}) no-repeat center/auto 100%` : `url(${ item.imagePc}) no-repeat center top/auto 100%` }"/>
          </el-carousel-item>
        </el-carousel>
        <div class="flex justify-center safePadding">
          <button class="btn" @click="$router.push('/about')"><span>{{$t("home.home.btn")}}</span></button>
        </div>
      </div>
      <div class="section2 content_wrapper paddingTop text-center">
        <div class="content_info safePadding">
          <div class="content_title">{{$t("home.serve.title")}}</div>
          <div class="content_desc text-more">{{ecologyInfo.brief}}</div>
        </div>
        <!-- h5 -->
        <el-carousel class="carousel" :interval="3000" type="card" height="340px" indicator-position="none">
          <el-carousel-item  v-for="(item,key) in ecology" :key="key" >
            <div class="itemBox" @click="linkDetail(item.id,'serve')">
              <div class="itemShadow"></div>
              <div class="itemWrapper">
                <div class="itemContent">
                  <div class="contentImg" :style="{ background:`url(${item.imagePc}) no-repeat center/100% auto` }"></div>
                  <div class="contentText">
                    <div class="contentTitle ">{{item.title}}</div>
                    <div class="contentDesc  text-more5">{{item.brief}}</div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <!-- pc -->
        <div class="carousel_item" >
          <div class="itemBox safePadding" v-for="(item,key) in ecology" :key="key" @click="linkDetail(item.id,'serve')">
            <div class="itemShadow"></div>
            <div class="itemWrapper">
              <div class="itemContent">
                <div class="contentImg" :style="{ background:`url(${item.imagePc}) no-repeat center/100% auto` }"></div>
                <div class="contentText">
                  <div class="contentTitle">{{item.title}}</div>
                  <div class="contentDesc text-more5">{{item.brief}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-center safePadding">
          <button class="btn" @click="$router.push('/serve')">
            <img class="icon"  src="/images/bg/btn-icon.png"/>
            <span>{{$t("home.serve.btn")}}</span>
          </button>
        </div>
      </div>

      <div class="section3 content_wrapper safePadding paddingTop" >
        <div class="content_info ">
          <div class="content_title">{{$t("home.updates.title")}}</div>
          <div class="content_desc">{{newsInfo.brief}}</div>
        </div>

        <!-- h5 -->
        <el-carousel class="carousel" :interval="3000" height="380px" >
          <el-carousel-item v-for="(item,key) in news" :key="key" >
            <div class="box" @click="linkDetail(item.id,'news')">
              <div class="itemBox" >
                <div class="itemWrapper">
                  <div class="itemContent">
                    <div class="contentImg" :style="{background:`url(${item.imagePc}) no-repeat center/100% auto`}"></div>
                  </div>
                </div>
              </div>
              <div class="contentText">
                <div class="contentTitle">{{item.title}}</div>
                <div class="contentDesc text-blue">{{moment(item.createTime).format("YYYY-MM-DD")}}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <!-- pc -->
        <div class="carousel_item ">
          <div class="box" v-for="(item,key) in news" :key="key" @click="linkDetail(item.id,'news')">
            <div class="itemBox"  >
              <div class="itemWrapper">
                <div class="itemContent">
                  <div class="contentImg" :style="{background:`url(${item.imagePc}) no-repeat center/100% auto`}"></div>
                </div>
              </div>
            </div>
            
            <div class="contentText">
              <div class="contentTitle">{{item.title}}</div>
              <div class="contentDesc text-blue">{{moment(item.createTime).format("YYYY-MM-DD")}}</div>
            </div>
          </div>
        </div>

        <div class="flex justify-center">
          <button class="btn" @click="$router.push('/news')"><span>{{$t("home.updates.btn")}}</span></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Api from '@/api/formal.js'
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      homeCarousel: 0,
      banner:[],
      ecology:[],
      news:[],
      ecologyInfo:{},
      newsInfo:{},
    };
  },
  created() {
    // this.getBanner();
    // this.getEcology();
    // this.getNews();
    this.getHome();
    this.$watch('$store.state.lang', (newVal, oldVal) => {
      this.getHome();
      // this.getBanner();
      // this.getEcology();
      // this.getNews();
    })


  },
  
  computed: {
    
  },
  mounted() {
    const that = this
    that.getHeight()
    window.addEventListener('resize',function(){
      that.getHeight()
    },false);
  },
  methods: {
    moment,
    getHeight(){ 
      const clientWidth = `${document.documentElement.clientWidth}`;
      const offsetWidth = this.$refs["ccc"].offsetWidth
      const clientHeight = `${document.documentElement.clientHeight}`
      console.log( clientWidth, clientWidth  < 1080,clientWidth < 1080? offsetWidth*600/375 : clientHeight )
      this.homeCarousel = Number(clientWidth) < 1080? offsetWidth*600/375 : clientHeight-80
    },
    linkDetail:function(id,type){ 
      console.log(id);
      if(type=='serve'){ 
        this.$router.push({ path:"/serve/detail/"+id })
      }
      if(type=='news'){ 
        this.$router.push({ path:"/news/detail/"+id })
      }
    },

    getHome () {
      Api.getHome({}).then(res => {
        console.log( res.data )
        this.banner = res.data.carouselList
        this.ecology = res.data.ecology
        this.ecologyInfo = res.data.ecologyCate
        this.news = res.data.news
        this.newsInfo = res.data.newsCate
      })
    },
  },  
  beforeDestroy() {
  },
};
</script>
<style lang="less" scoped>
.home{ position: relative;top: 0; left: 0; min-height: 100vh; width: 100%;  background:url('/images/bg.jpg') repeat-y center top/ 100%}
.home_content{ display: flex; flex-direction: column; grid-gap: 48px; overflow:hidden}

.content_wrapper{ width: 100%; margin:0 auto; position:relative; top:0;left:0; display:flex;flex-direction:column;grid-gap:16px;&.paddingTop{padding-top:24px; }}
.content_wrapper+.content_wrapper{ 
  &::after{ content:'';display: block; width:140px;height:1px;position: absolute; top: 0px;left: calc( 50% + 10px );background-color:#FF8963; box-shadow: 0px 0px 4px 0px #FF8963;}
  &::before{ content:'';display: block; width:140px;height:1px;position: absolute; top: 0px;right: calc( 50% + 10px );background-color:#FF8963;box-shadow: 0px 0px 4px 0px #FF8963;}
}
.content_info{ text-align:center;
  display:flex;flex-direction:column; gap:24px;
  .content_title{ font-size:20px };
  .content_desc{ font-size:12px };
}

/* 
  common
*/
.safePadding{ padding:0 20px}
/*
*  轮播
*/
.section1{ 
  // #0f2a45
  .item{ display:block; height:100%;width:100%;box-shadow: 0px -20px 10px 0px #0e2641 inset; cursor:pointer}
}
/*
*  生态
*/
.section2{ 
  
  .itemBox  { 
    cursor: pointer;
    position:relative; width:100%;height:100%;
    .itemShadow{ 
      position:absolute;top:0px; left:0px;z-index:1;border-radius: 10px;
      border: 2px solid rgba(255, 137, 99, 0.25);width:100%;height:100%;
      &::before{ content:'';display: block; width:20px;height:5px;z-index:1;position: absolute; bottom: -3px; left: calc( 50% - 10px );background-color:#0F2841; }
    }
    .itemWrapper{
        position:absolute; top:0px; left:0px; z-index:3; border-radius: 10px;
        width:100%;height: calc(100% - 6px);border: 2px solid #FF8963;
        &::before{ content:'';display: block; width:20px;height:5px;position: absolute; bottom: -5px; left: calc( 50% - 10px );z-index:1;background-color:#0F2841;}
        &::after{ content:'';display: block; width:100%;height:100%;position: absolute; bottom: 0; left: 0;z-index:3;box-shadow: 0px 1px 30px 0px rgba(255, 137, 99, 0.40) inset;border-radius: 10px;}
    }
    .itemContent{ 
      position:absolute; top:0px; left:0px;z-index:2;border-radius: 10px;
      width:100%;height: 100%;background:#102C4B;
      filter: drop-shadow(0px 0px 10px rgba(255, 137, 99, 0.40));
      .contentImg{ width:100%; aspect-ratio: 1000/580; border-radius: 10px;box-shadow: 0px -20px 20px 0px #102C4B inset;}
      .contentText{  display:flex;flex-direction:column;gap:24px; padding:0 10px; }
      .contentTitle{font-size:16px}
      .contentDesc{font-size:12px}
    }
  }

  
  .el-carousel__item{ 
      /deep/.el-carousel__mask{ display: none !important; }
      height:calc(100% - 30px); margin-top:20px; overflow:unset;opacity: 0.3;
      &.is-active{ opacity: 1;}
  }
  .carousel_item{ display:none }
}



.section3{

  .box{ cursor: pointer; display:flex; flex-direction:column; gap:24px }
  .itemBox { 
    // 375-40
    border-radius: 10px;border: 2px solid rgba(255, 137, 99, 0.25); width:100%; height:200px;position: relative;
    &::before{ content:'';display: block; width:20px;height:5px;z-index:1;position: absolute; bottom: -3px; left: calc( 50% - 10px );background-color:#0F2841; }
  }
  .itemWrapper{
    border-radius: 10px;border: 2px solid #FF8963;width:100%;height: calc(100% - 6px) ;position:absolute; top:0px; left:0px; 
    &::after{ content:'';display: block; width:100%;height:100%;position: absolute; bottom: 0; left: 0;z-index:3;box-shadow: 0px 1px 30px 0px rgba(255, 137, 99, 0.40) inset,}
    &::before{ content:'';display: block; width:20px;height:5px;position: absolute; bottom: -5px; left: calc( 50% - 10px );z-index:1;background-color:#0F2841;}
  }
  
  .itemContent{ 
    position:absolute; top:0px; left:0px;z-index:2;width:100%;height:100%;background:#102C4B;border-radius: 10px;
    filter: drop-shadow(0px 0px 10px rgba(255, 137, 99, 0.40));
    .contentImg{ width:100%; height:100%; border-radius: 10px;box-shadow: 0px -20px 20px 0px #102C4B inset;}
  }

  .contentText{ display:flex;flex-direction:column;gap:16px;text-align:center }
  .contentTitle{ font-size:16px }
  .contentDesc{ font-size:12px }


  .el-carousel__item{ 
      /deep/.el-carousel__mask{ display: none !important; }
      height:calc(100% - 30px); margin-top:20px; overflow:unset;opacity: 0.3;
      &.is-active{ opacity: 1;}
  }
  .carousel_item{ display:none }

}
 

@media screen and  (min-width: 1080px)     {
  .home{ background:url('/images/bg_pc.jpg') repeat-y center top/ 100%}
  .home_content{ grid-gap: 60px; }
  .safePadding{ max-width:1400px; margin:0 auto; padding:0;width:100%}
  .content_wrapper{  margin:0 auto; min-height: calc(100vh - 80px); gap:60px;
    &.paddingTop{ padding-top:60px; }
  }

  .content_wrapper+.content_wrapper{ 
  &::after{  width:20% ;height:1px;}
  &::before{  width:20% ;height:1px;}
}


  .content_info{ gap:60px;
    .content_title{ font-size:48px };
    .content_desc{ font-size:20px };
  }

  /deep/.section1{ 
    .el-carousel__indicator .el-carousel__button { width:200px; height:4px }
  }
   


  .carousel{ display:none}
  .section2{ 
    .carousel_item{ display:flex; flex-direction:column;gap:60px}
    .itemBox { height:320px;
      .itemWrapper{ height: calc(100% - 12px); }
      .itemContent{ display:flex;
        .contentImg{ height:100%; box-shadow: -20px 0px 20px 0px #102C4B inset; flex:1}
        .contentText{ display:flex;flex-direction:column;gap:24px;flex:1; padding:0 30px; justify-content:center;flex:1}
        .contentTitle{ font-size:28px }
        .contentDesc{ font-size:20px }
      }
    }
  }
  .section3{
    .carousel_item{ display:block }
    .carousel_item{   
      display:grid; grid-template-columns:repeat(3,1fr); grid-gap:60px;
    }
    .contentTitle{ font-size:20px }
    .contentDesc{ font-size:20px }
  }
}
</style>




 