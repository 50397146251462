/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-08-26 10:46:34
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-08-30 18:07:29
 * @FilePath: \do2f:\Workspace\TechMaker\src\utils\signature.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import md5 from "md5";

function randomDigit() {
    let code = '';
    let possibleChars = '0123456789';

    for (let i = 0; i < 8; i++) {
        let randomIndex = Math.floor(Math.random() * possibleChars.length);
        code += possibleChars.charAt(randomIndex);
    }
    return code;
}

export const signatureStr = function(params){
    let result = {
        ...params,
        'random': randomDigit(),
        'timestamp': Math.floor(Date.now() / 1000)
    }
    let sortedKeys = Object.keys(result).sort();
    let resultStr = '';
    for (let i = 0; i < sortedKeys.length; i++) {
        let key = sortedKeys[i];
        let value = result[key];

        if (value !== undefined && value !== null && value !== '') {
            resultStr += `${key}|${value}`;
        }
    }
    resultStr += 'aRaSDDMwXPGTBJ56hn7YBdNPZd5pAB7xMKBNrNpBScMXswdTsyNRtac6b6jj4i8GGtRdMic3CmDX3ZdnCX4rPeZ5GYp3KTNftp4Mx6NGWArZGPzjzdZBxMct'
    let signature = md5(resultStr);
    result['signature'] = signature;
    // console.log(resultStr)
    return result;
}