<!--
* @Author: your name
* @Date: 2022-03-26 14:39:17
 * @LastEditTime: 2023-12-23 19:08:54
 * @LastEditors: 叹息桥丶 648161532@qq.com
* @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
* @FilePath: \vue-portale:\demo\revoland\src\view\home.vue
-->
<template>
  <div class="news text-white text16 ">
    <div class="news_content " >
      <div class="content_info">
        <div class="content_title">{{$t("home.menu.notice")}}</div>
        <div class="content_desc text-more" v-if="info.brief">{{info.brief}}</div>
      </div>
      <div class="item" v-for="(item,key) in list" :key="key" @click="linkDetail(item.id)">
        <div class="itemBox" >
          <div class="itemShadow"></div>
          <div class="itemWrapper">
            <div class="itemContent"  :style="{ background:`url(${item.imagePc}) no-repeat center/100% auto` }"/>
          </div>
        </div>
        <div class="contentText">
          <div class="contentView text-orange">  {{$t("home.home.detail")}} </div>
          <div class="contentTitle text-more3">{{item.title}}</div>
          <div class="contentDesc text-blue">{{ moment(item.createTime).format("YYYY-MM-DD") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import * as Api from "@/api/formal.js";
import moment from "moment";

export default {
  components: {},
  computed: {},
  created() {
    this.getList()
    this.getContent()
    this.$watch('$store.state.lang', (newVal, oldVal) => {
      this.list=[];
      this.total=0;
      this.params.page=1;
      this.getList()
      this.getContent()
    })
    window.addEventListener("scroll",this.debounce(this.handleScroll,300) );
  },
  beforeDestroy() {
    window.removeEventListener("scroll",this.debounce(this.handleScroll,300));
  },
  mounted() {},
  data() {
    return {
      params:{ page:1,pageSize:3},
      total:0,
      list: [],
      info:[]
    }
  },
  methods: {
    moment,
    debounce(fn, wait, immediate) {
        let timeout;
        return function () {
            //每次触发scrolle，先清除定时器
            clearTimeout(timeout);
            //指定多少秒后触发事件操作handler
            timeout = setTimeout(fn, wait);
        };
    },
    throttle (fn, delay) {
        var prev = Date.now()
        return function () {
            var context = this;
            var args = arguments;
            var now = Date.now();
            if (now - prev >= delay) {
              fn.apply(context, args);
                prev = Date.now();
            }
        }
    },
    getList() {
      Api.getList({ ...this.params,type: 3 }).then((res) => {
        console.log(res.data )
        this.list = this.list.concat(res.data.list);
        this.total = res.data.total
      });
    },
    loadMore(){ 
      console.log( "加载更多")
      console.log( this.total , this.list.length )
      if(this.list.length < this.total){ 
        console.log("出发加载")
        this.params.page++
        this.getList()
      }
    },

    getContent() {
      Api.getContent({ type: 3 }).then((res) => {
        console.log(res.data)
        this.info = res.data;
      });
    },
    linkDetail(id){ 
      this.$router.push({ path: "/notice/detail/"+id})
    },

    handleScroll(e) {
        const scrollY = window.scrollY
        const offsetHeight = `${document.documentElement.offsetHeight}`
        const clientHeight = `${document.documentElement.clientHeight}`
        if (offsetHeight - clientHeight - scrollY < 50){ 
          this.loadMore();
        }
    },
  },
};
</script>

<style lang="less" scoped>
.news{ position: relative;top: 0; left: 0; min-height: 100vh; width: 100%; background:url('/images/bg.jpg') repeat-y center top/ 100%}
.news_content {width:100%;min-height: 100vh;display: flex; flex-direction: column; grid-gap: 48px; padding:36px 20px;text-align:center }
.content_info{
  display:flex;flex-direction:column; gap:24px;
  .content_title{ font-size:20px };
  .content_desc{ font-size:12px };
}


.item{ display:flex ; flex-direction:column; gap:24px;cursor: pointer; }

.itemBox{ 
  position:relative;
  height:220px;
  .itemShadow  { 
    height:100%;
    border-radius: 10px;border: 2px solid rgba(255, 137, 99, 0.25);width:100%;position:relative;
    &::before{ content:'';display: block; width:20px;height:5px;z-index:1;position: absolute; bottom: -3px; left: calc( 50% - 10px );background-color:#0F2841; }
  }

  .itemWrapper{ 
    height: calc( 100% - 12px);
    border-radius: 10px;border: 2px solid #FF8963;width:100%;position:absolute; top:0px; left:0px; 
    &::after{ content:'';display: block; width:100%;height:100%;position: absolute; bottom: 0; left: 0;z-index:3;box-shadow: 0px 1px 30px 0px rgba(255, 137, 99, 0.40) inset}
    &::before{ content:'';display: block; width:20px;height:5px;position: absolute; bottom: -5px; left: calc( 50% - 10px );z-index:1;background-color:#0F2841;}
  }
  .itemContent{ 
    position:absolute; top:0px; left:0px;z-index:2;
    width:100%;height:100%;background:#102C4B;border-radius: 10px;
    filter: drop-shadow(0px 0px 10px rgba(255, 137, 99, 0.40));
    .contentImg{ width:100%;aspect-ratio:1000/580; box-shadow: 0px -20px 20px 0px #102C4B inset; img{ width:100%;height:100% } 
      border-radius: 10px;
    };
  }
}
.contentText{ display:flex; flex-direction:column; gap:12px; };
.contentDesc{ font-size:12px }
.contentTitle{ font-size:16px }
.contentView{ display:none }


@media screen and (min-width: 1080px) {
  .news{ background:url('/images/bg_pc.jpg') repeat-y center top/ 100%}
  .news_content{ padding:60px 0 0; max-width: 960px; margin:0 auto;gap:60px}
  .content_info{ gap:60px;
    .content_title{ font-size:48px };
    .content_desc{ font-size:20px };
  }
  .item{  flex-direction:row; gap:60px }
  .itemBox{ height:290px; width:500px; }
  .contentText{ flex:1; gap:16px; text-align:left; flex-direction:column-reverse; justify-content:flex-end; };
  .contentTitle{ font-size:28px }
  .contentDesc{ font-size:20px }
  .contentView{ display:block;font-size:20px;font-weight:700 }
}

</style>
  